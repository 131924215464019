import { useState, useEffect } from 'react'
import Icon from '@/components/icon/icon'
import dynamic from 'next/dynamic'
import { prepareCollectionData } from '@/lib/product-helper'

const ProductList = dynamic(() => import('@/components/product/product-list'))

const ProductFeaturedPopup = ({ className, position = 'right', productsData}) => {
  const [products, setProducts] = useState([])
  const [isProductsVisible, setIsProductsVisible] = useState(false)

  const displayProductList = () => {
    setTimeout(() => {
      setIsProductsVisible(true)
    }, 150)
  }

  useEffect(() => {
    if (productsData && productsData.length > 0) {
      prepareCollectionData(productsData).then((data) => {
        setProducts(data)
      })
    }
  }, [productsData])

  return (
     <>
      <div className={`product-featured-popup absolute rounded-full border border-white ${className} ${position == 'right' ? 'product-featured-popup--right': 'product-featured-popup--left'}`}>
        <div className="relative">
          <div className={`${!isProductsVisible ? 'plus-icon-on-show' : ''}`} onTouchStart={displayProductList} onMouseEnter={displayProductList}>
              <Icon
                className={`cursor-pointer text-white fill-current w-7 h-7`}
                viewBox={`0 0 36 36`}
                size={36}
                icon='plus-circle' />
          </div>
          {
            products && products.length > 0 && (
              <ProductList
                showProductMenu={isProductsVisible}
                setShowProductMenu={setIsProductsVisible}
                products={products}
                detailRightSide={position == 'right' ? false : true} 
              />
            )
          }
        </div>
      </div>
      <style jsx>{
        `
          .plus-icon-on-show {
            animation: plusIcon 0.8s forwards;
          }

          @keyframes plusIcon {
            0% {
                transform: rotateZ(180deg);
            }
            100% {
                transform: rotateZ(90deg);
            }
          }

          .product-featured-popup {
            height: 36px;
            padding: 2px;
            width: 36px;
          }
        `
      }</style>
    </>
  )
}

export default ProductFeaturedPopup